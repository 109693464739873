aside ul ul {
  padding-left: 22px !important;
}

.sideNavSubMenu {
  padding-left: 26px !important;
}

.accordion .card .sideNavSubMenu li:hover {
  padding-left: 20px;
  transform: translateX(5px);
  color: #0288d1;
  border-left: 5px solid #0288d1;
}

.sideNavSubMenu span:hover {
  color: #0288d1 !important;
}

.sideNavSubMenuCardBody span,
.sideNavSubMenuCardBody:hover,
.sideNavSubMenuCardBody span:hover {
  color: var(--creamWhite) !important;
}

.sideNavSubMenuCardBody .sideNavIcon {
  background-color: rgba(0, 0, 0, 1);
}

.sideNavSubMenu .card-body {
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.sideNavSubMenu .card-body li span {
  white-space: nowrap;
  color: var(--sideNavMenuItem);
}

.sideNavSubMenuCardBody .card-body li span {
  white-space: nowrap;
  color: var(--creamWhite) !important;
}

.sideNavSubMenu .card-body li {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  width: 1000%;
}

.asideUlLi .card-header .button span {
  margin-right: 10px;
  /* // margin-left: 1.6em; */
}

.sideNavIcon {
  height: 25px;
  width: 25px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 3.5em;
  font-weight: bold;
  margin-left: -6px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.accordion .card {
  background: var(--sideNavMenuBackground);
  box-shadow: unset;
  border: unset;
}

.asideUlLi .card-header {
  width: 100%;
}

aside .accordion .card-header {
  display: flex;
}

.button {
  /* // left: 28px; for localhost */
  left: 38px; /* // for build */
  padding: 0px;
  position: absolute;
  top: 8px;
}

.accordion li {
  padding: 0px;
  margin: 0px;
}

.asideUlLi a {
  color: var(--sideNavMenuItem);
}

.sideNavSubMenu a {
  color: var(--sideNavMenuItem);
}

